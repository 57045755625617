<template>
  <!-- :style="!this.$ipc ? 'margin-top:100px;' :'' " -->
  <a-layout id="app-layout-sider" > 
    <a-layout-sider
      v-model="collapsed"
      theme="light"
      class="layout-sider"
    >
      <div class="logo">
        <img class="pic-logo" :src="myHeader" @click="menuHandle(6)">
      </div>
      <div class="splint-line"></div>
      <div class="ls" :class="current == 1 ? 'ls-active' : ''" key="1"  @click="menuHandle(1)">
        <div class="new-dot" v-if="lsshow"></div>
      </div>
      <div class="qz" :class="current == 2 ? 'qz-active' : ''" key="2" @click="menuHandle(2)">
        <div class="new-dot" v-if="qzshow"></div>
      </div>
      <div class="hy" :class="current == 3 ? 'hy-active' : ''"  key="3" @click="menuHandle(3)">
        <div class="new-dot" v-if="hyshow"></div>
      </div>

      <div class="seting" @click="menuHandle(4)"></div>
      <div class="splint-line" style="position: absolute;bottom: 54px; left:17px;"></div>
      <div class="sf" @click="menuHandle(5)"></div>
    </a-layout-sider>
    <a-layout>
      <a-layout-content class="layout-content" >
        <router-view @setShow="changeShow" @setShowItem="changeShowItem" />
      </a-layout-content>
    </a-layout>
    <a-modal v-model="visible" title="设置" @ok="handleOk" :footer="null" width="400px"  :centered="true">
      <a-tabs v-model="activeKey" tab-position="left">
        <a-tab-pane key="1" tab="通知设置">
          <div class="seting-1"><span>新消息声音通知</span><a-switch style="margin-top: 1px; margin-left: 20px;" v-model="newMessageNotice" @change="seting(1)" size="small" /></div>
          <div class="seting-1"><span>通知显示消息详情</span><a-switch style="margin-top: 1px; margin-left: 20px;" v-model="noticeShowDetail" @change="seting(1)" size="small" /></div>
          <div class="seting-1"><span>消息弹窗是否开启</span><a-switch style="margin-top: 1px; margin-left: 20px;" v-model="messagePopup" @change="seting(1)" size="small" /></div>
          <div class="seting-1">当前用户ID:{{getMyNameID()}}</div>
          <div class="seting-1"><a-button type="default" @click.prevent="clearSave" size="middle" style="font-size: 12px;">清理缓存</a-button></div>
        </a-tab-pane>
        <a-tab-pane key="2" tab="快捷键">
          <div class="seting-2" style="margin-top: 6px;"><span>发送消息</span></div>
          <div class="seting-2" style="margin-top: 16px;">
            <a-radio-group v-model="sendKeyChecked" @change="seting(1)">
              <a-radio :value="1" >Enter</a-radio><a-radio :value="2" >Ctrl+Enter</a-radio>
            </a-radio-group>
          </div>
          <div class="seting-2" style="margin-top: 20px;"><a-button type="default" @click.prevent="seting(2)" size="middle" style="font-size: 12px;">恢复默认设置</a-button></div>
        </a-tab-pane>
        <!-- <a-tab-pane key="3" tab="文件管理">Content of Tab 3</a-tab-pane> -->
        <a-tab-pane key="4" tab="关于">
           <div class="seting-2" style="margin-top: 6px;">版本信息</div>
           <div class="seting-2" style="margin-top: 6px;">缤丽优家1.1.9(20240215)</div>
           <div class="seting-2" style="margin-top: 12px;"><a-button type="default"  size="middle" style="font-size: 12px;" @click="updateVer">刷新缓存</a-button></div>
        </a-tab-pane>
      </a-tabs>
    </a-modal>
  </a-layout>
</template>
<script>
import { EaseChatClient } from '@/IM/initwebsdk'
import storage from 'store2'
import { Modal } from 'ant-design-vue';
import { ipcApiRoute,requestHttpGet,requestHttpPostByOnline} from '@/api/main'
export default {
  name: 'MainSider',
  data() {
    return {
      collapsed: true,
      default_key: '1',
      current: '',
      visible:false,
      activeKey:"1",
      newMessageNotice:true,
      noticeShowDetail:true,
      messagePopup:true,
      sendKeyChecked:true,
      myHeader:"",
      qzshow:false,
      hyshow:false,
      lsshow:false,
      apiUrl:'https://commonapi.binliyoujia.com',
      // apiUrl:'http://localhost:48080',
    };
  },
  created () {
  },
  mounted () {
    if(this.$route.name == "MainIndex"){
      this.default_key = 1

    }
    if(this.$route.name == "CrowdIndex"){
      this.default_key = 2
      
    }
    if(this.$route.name == "FriendIndex"){
      this.default_key = 3
      
    }
    this.menuHandle()
  },
  methods: {
    clearSave(){
      if (this.$ipc) {
            this.$ipc.invoke(ipcApiRoute.clearCache, 2).then(result => {
              console.log(result)
            })
      }
    },
    updateVer(){

    },
    getMyNameID(){
      return storage.get("ImAcount")
    },
    changeShow(e){
      if(e.type == 1 && this.$route.name != 'MainIndex'){
        this.lsshow = e.show
      }else if(e.type == 2 && this.$route.name != 'CrowdIndex'){
        this.qzshow = e.show
      }else if(e.type == 3 && this.$route.name != 'FriendIndex'){
        this.hyshow = e.show
      }
      
    },
    changeShowItem(e){
      if(e.type == 1){
        this.lsshow = e.show
        this.current = e.type
      }else if(e.type == 2){
        this.qzshow = e.show
        this.current = e.type
      }else if(e.type == 3 ){
        this.hyshow = e.show
        this.current = e.type
      }
    },
    async initSeting(){
      //初始化设置
      let userInfo = await requestHttpGet(this.apiUrl+'/admin-api/supplier/user/user-info')
      this.newMessageNotice = userInfo.data.newMessageNotice
      this.noticeShowDetail = userInfo.data.noticeShowDetail
      this.messagePopup =  userInfo.data.messagePopup
      this.sendKeyChecked = userInfo.data.sendKeyChecked
      let obj = {
        newMessageNotice:this.newMessageNotice,
        noticeShowDetail:this.noticeShowDetail,
        messagePopup:this.messagePopup,
        sendKeyChecked:this.sendKeyChecked,
      }
      storage.set(this.getMyNameID()+"seting",JSON.stringify(obj))
    },
    handleOk(){
      this.visible = false;
    },
    async seting(e){
      let obj = {
        newMessageNotice:this.newMessageNotice,
        noticeShowDetail:this.noticeShowDetail,
        messagePopup:this.messagePopup,
        sendKeyChecked:this.sendKeyChecked,
      }
      if(e == 2){
        //恢复默认
        obj.newMessageNotice = true
        obj.noticeShowDetail = true
        obj.messagePopup = true
        obj.sendKeyChecked = true
      }
      storage.set(this.getMyNameID()+"seting",JSON.stringify(obj))
      await requestHttpPostByOnline(this.apiUrl+'/admin-api/supplier/user/setting-user-info', obj)
    },
    async menuHandle (e) {
      this.myHeader = storage.get("UserHeader")
      this.current = e ? e : this.default_key;
      console.log(this.current)
      if(this.current == 1){
        this.$router.push({ name: "Main", params: {id:this.current,ir:false}})
        this.lsshow = false
      }
      if(this.current == 2){
        this.$router.push({ name: "Crowd", params: {id:this.current,ir:false}})
        this.qzshow = false
      }
      if(this.current == 3){
        this.$router.push({ name: "Friend", params: {id:this.current,ir:false}})
        this.hyshow = false
      }
      if(this.current == 4){
        this.initSeting()
        this.visible = true;
      }
      if(this.current == 5){
        this.outVisible = true;
        let that = this
          Modal.confirm({
            cancelText:"取消",
            okText:"确定",
            centered:true,
            title: () => '您确定退出当前账号吗？',
            content: () => '',
            onOk() {
                EaseChatClient.close();
                storage.remove("ImAcount")
                storage.remove("ImPassword")
                storage.remove("UserName")
                storage.remove("UserHeader")
                storage.remove("IMUser")
                storage.remove("IMToken")
                storage.remove("ks")
                storage.remove("us")
                storage.remove("shopType")
                storage.remove("newFiledsList")
                storage.remove("newGroupsList")
                that.$router.push('/login')
            },
            onCancel() {},
          });
      }
    },
  },
};
</script>
<style lang="less" scoped>
// 嵌套
#app-layout-sider {
  position: relative;
  width: 100%;
  height: 100%;
  margin: auto;
  overflow: hidden;
  border:1px solid #eee;
  .seting{
    background: url("~@/assets/st.png");
    background-size: 100%;
    width: 24px;
    height: 24px;
    margin: auto;
    bottom: 94px;
    cursor: pointer;
    position: absolute;
    left: 16px;
  }
  .sf{
    background: url("~@/assets/sf.png");
    background-size: 100%;
    width: 24px;
    height: 24px;
    margin: auto;
    cursor: pointer;
    bottom: 35px;
    position: absolute;
    left: 16px;
  }
  
  .ls{
    background: url("~@/assets/ls1.png");
    background-size: 100%;
    width: 24px;
    height: 24px;
    margin: auto;
    margin-bottom: 35px;
    cursor: pointer;
    position: relative;
  }
  .ls-active{
    background: url("~@/assets/ls2.png");
    background-size: 100%;
  }
  .qz{
    background: url("~@/assets/qz1.png");
    background-size: 100%;
    width: 24px;
    height: 24px;
    margin: auto;
    margin-bottom: 35px;
    cursor: pointer;
    position: relative;
  }
  .qz-active{
    background: url("~@/assets/qz2.png");
    background-size: 100%;
  }
  .hy{
    background: url("~@/assets/hy1.png");
    background-size: 100%;
    width: 24px;
    height: 24px;
    margin: auto;
    margin-bottom: 35px;
    cursor: pointer;
    position: relative;
  }
  .hy-active{
    background: url("~@/assets/hy2.png");
    background-size: 100%;
  }
  .pic-logo {
    height: 36px;
    width: 36px;
    border-radius: 50%;
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;
  }
  .splint-line{
    width: 22px;
    height: 1px;
    opacity: 1;
    background: #F7F7F7;
    margin: auto;
    margin-bottom: 20px;
  }
  .layout-sider {
    width: 56px !important;
    height: 100%;
    flex: 0 0 56px !important;
    max-width: 56px !important;
    min-width: 56px !important;
  }
  .menu-item {
    height: 100%;
  }
  .ant-menu-inline-collapsed{
    width: 56px;

  }
  .layout-content {
    //background-color: #fff;
  }
  
}
.new-dot{
  width: 9px;
  height: 9px;
  opacity: 1;
  background: #ED2616;
  position: absolute;
  top: 2px;
  right: 0px;
  border-radius: 50%;
}
.login-button {
    width: 160px;
    height: 32px;
    border-radius: 4px;
    background: linear-gradient(90deg, #FE5D42 0%, #ED2616 100%);
    border: none;
    color: #fff;
    margin-top: 21px;
    cursor: pointer
  }
/deep/ .ant-modal-body {
  border-radius: 10px;
  padding: 0px;
}
/deep/ .ant-modal-header {
  border-radius: 10px 10px 0px 0px;
  border-bottom:none;
}
/deep/ .ant-modal-content{
  border-radius: 10px;
}
/deep/ .ant-tabs{
  height: 300px;
}
/deep/ .ant-tabs .ant-tabs-left-bar .ant-tabs-tab{
  text-align: left;
}
/deep/ .ant-tabs .ant-tabs-left-bar .ant-tabs-tab, .ant-tabs .ant-tabs-right-bar .ant-tabs-tab{
  margin: 0px;
  font-size: 12px;
  padding-right: 32px;
  padding-left: 24px;
}
/deep/ .ant-tabs .ant-tabs-left-bar .ant-tabs-ink-bar, .ant-tabs .ant-tabs-right-bar .ant-tabs-ink-bar{
  width: 1px;
  height: 12px !important;
  top: 12px !important; 
}
/deep/ .ant-tabs-nav .ant-tabs-tab-active{
  color: #ED2616;
}
/deep/ .ant-tabs .ant-tabs-left-content{
  padding-left: 34px;
}
.seting-1{
  font-size: 12px;
  display: flex; 
  width: 220px; 
  margin-top: 9px; 
  justify-content: space-between;
   margin-bottom: 15px;
}
.seting-2{
  font-size: 12px;
  display: flex; 
  width: 220px; 
  margin-top: 2px; 
  justify-content: space-between;
}
</style>

<template>
  <div id="app">
    <router-view/>
    <audio id="ring" src="@/assets/ts.wav" controls hidden></audio>
  </div>
</template>

<script>
import ring from '@/assets/ts.wav'
export default {
  name: 'App',
  components: {},
  data() {
    return {};
  },
  watch: {},
  methods: {}
}
</script>
<style>

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  border-radius: 10px;
  user-select: none;
}
body{
  background: transparent;
  overflow: hidden;
}
</style>

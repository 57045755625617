// const emojis = [
//     '😀',
//     '😃',
//     '😄',
//     '😁',
//     '😆',
//     '😅',
//     '🤣',
//     '😂',
//     '🙂',
//     '🙃',
//     '😉',
//     '😊',
//     '😇',
//     '😍',
//     '🤩',
//     '😘',
//     '😗',
//     '😚',
//     '😙',
//     '😋',
//     '😛',
//     '😜',
//     '🤪',
//     '😝',
//     '🤑',
//     '🤗',
//     '🤭',
//     '🤫',
//     '🤔',
//     '🤐',
//     '🤨',
//     '😐',
//     '😑',
//     '😶',
//     '😏',
//     '😒',
//     '🙄',
//     '😬',
//     '🤥',
//     '😌',
//     '😔',
//     '😪',
//     '🤤',
//     '😴',
//     '😷',
//     '🤒',
//     '🤕',
//     '🤢',
//     '🤮',
//     '🤧',
//     '😵',
//     '🤯',
//     '🤠',
//     '😎',
//     '🤓',
//     '🧐',
//     '😕',
//     '😟',
//     '🙁',
//     '😮',
//     '😯',
//     '😲',
//     '😳',
//     '😦',
//     '😧',
//     '😨',
//     '😰',
//     '😥',
//     '😢',
//     '😭',
//     '😱',
//     '😖',
//     '😣',
//     '😞',
//     '😓',
//     '😩',
//     '😫',
//     '😤',
//     '😡',
//     '😠',
//     '🤬',
//     '😈',
//     '👿',
//     '💀',
//     '💩',
//     '🤡',
//     '👹',
//     '👺',
//     '👻',
//     '👽',
//     '👾',
//     '🤖',
//     '😺',
//     '😸',
//     '😹',
//     '😻',
//     '😼',
//     '😽',
//     '🙀',
//     '😿',
//     '😾',
//     '💋',
//     '👋',
//     '🤚',
//     '🖐',
//     '✋',
//     '🖖',
//     '👌',
//     '🤞',
//     '🤟',
//     '🤘',
//     '🤙',
//     '👈',
//     '👉',
//     '👆',
//     '🖕',
//     '👇',
//     '👍',
//     '👎',
//     '✊',
//     '👊',
//     '🤛',
//     '🤜',
//     '👏',
//     '🙌',
//     '👐',
//     '🤲',
//     '🤝',
//     '🙏',
//     '💅',
//     '🤳',
//     '💪',
//     '👂',
//     '👃',
//     '🧠',
//     '👀',
//     '👁',
//     '👅',
//     '👄',
//     '👶',
//     '🧒',
//     '👦',
//     '👧',
//     '🧑',
//     '👱',
//     '👨',
//     '🧔',
//     '👱‍',
//     '👨‍',
//     '👨‍',
//     '👩',
//     '👱‍',
//     '👩‍',
//     '👩‍',
//     '👩‍',
//     '👩‍',
//     '🧓',
//     '👴',
//     '👵',
//     '🙍',
//     '🙅',
//     '🙆',
//     '💁',
//     '🙋',
//     '🙇',
//     '🙇‍',
//     '🙇‍',
//     '🤦',
//     '🤷',
//     '🤷‍',
//     '🤷‍',
//     '👨‍⚕️',
//     '👩‍⚕️',
//     '👨‍🎓',
//     '👩‍🎓',
//     '👨‍🏫',
//     '👩‍🏫',
//     '👨‍⚖️',
//     '👩‍⚖️',
//     '👨‍🌾',
//     '👩‍🌾',
//     '👨‍🍳',
//     '👩‍🍳',
//     '👨‍🔧',
//     '👩‍🔧',
//     '👨‍🏭',
//     '👩‍🏭',
//     '👨‍💼',
//     '👩‍💼',
//     '👨‍🔬',
//     '👩‍🔬',
//     '👨‍💻',
//     '👩‍💻',
//     '👨‍🎤',
//     '👩‍🎤',
//     '👨‍🎨',
//     '👩‍🎨',
//     '👨‍✈️',
//     '👩‍✈️',
//     '👨‍🚀',
//     '👩‍🚀',
//     '👨‍🚒',
//     '👩‍🚒',
//     '👮',
//     '👮‍♂️',
//     '👮‍♀️',
//     '🕵',
//     '🕵️‍♂️',
//     '🕵️‍♀️',
//     '💂',
//     '💂‍',
//     '💂‍',
//     '👷',
//     '👷‍',
//     '👷‍',
//     '🤴',
//     '👸',
//     '👳',
//     '👳‍',
//     '👳‍',
//     '👲',
//     '🧕',
//     '🤵',
//     '👰',
//     '🤰',
//     '🤱',
//     '👼',
//     '🎅',
//     '🤶',
//     '🧙',
//     '🧚',
//     '🧛',
//     '🧜',
//     '🧝',
//     '🧞',
//     '🧟',
//     '💆',
//     '💇',
//     '🚶',
//     '🏃',
//     '💃',
//     '🕺',
//     '🕴',
//     '👯',
//     '🧖',
//     '🧖‍',
//     '🧖‍',
//     '🧘',
//     '👭',
//     '👫',
//     '👬',
//     '💏',
//     '👨‍',
//     '👩‍',
//     '💑',
//     '👨‍',
//     '👩‍',
//     '👪',
//     '👨‍👩‍👦',
//     '👨‍👩‍👧',
//     '👨‍👩‍👧‍👦',
//     '👨‍👩‍👦‍👦',
//     '👨‍👩‍👧‍👧',
//     '👨‍👨‍👦',
//     '👨‍👨‍👧',
//     '👨‍👨‍👧‍👦',
//     '👩‍👩‍👦',
//     '👩‍👩‍👧',
//     '👩‍👩‍👧‍👦',
//     '👩‍👩‍👦‍👦',
//     '👩‍👩‍👧‍👧',
//     '👨‍👦',
//     '👨‍👦‍👦',
//     '👨‍👧',
//     '👨‍👧‍👦',
//     '👨‍👧‍👧',
//     '👩‍👦',
//     '👩‍👦‍👦',
//     '👩‍👧',
//     '👩‍👧‍👦',
//     '👩‍👧‍👧',
//     '🗣',
//     '👤',
//     '👥',
//     '👣',
//     '🌂',
//     '☂',
//     '👓',
//     '🕶',
//     '👔',
//     '👕',
//     '👖',
//     '🧣',
//     '🧤',
//     '🧥',
//     '🧦',
//     '👗',
//     '👘',
//     '👙',
//     '👚',
//     '👛',
//     '👜',
//     '👝',
//     '🎒',
//     '👞',
//     '👟',
//     '👠',
//     '👡',
//     '👢',
//     '👑',
//     '👒',
//     '🎩',
//     '🎓',
//     '🧢',
//     '⛑',
//     '💄',
//     '💍',
//     '💼',
// ]
const emojis = [
    "[):]",
    "[:D]",
    "[;)]",
    "[:-o]",
    "[:p]",
    "[(H)]",
    "[:@]",
    "[:s]",
    "[:$]",
    "[:(]",
    "[:'(]",
    "[<o)]",
    "[(a)]",
    "[8o|]",
    "[8-|]",
    "[+o(]",
    "[|-)]",
    "[:|]",
    "[*-)]",
    "[:-#]",
    "[^o)]",
    "[:-*]",
    "[8-)]",
    "[(|)]",
    "[(u)]",
    "[(S)]",
    "[(*)]",
    "[(#)]",
    "[(R)]",
    "[({)]",
    "[(})]",
    "[(k)]",
    "[(F)]",
    "[(W)]",
    "[(D)]"
]
export default emojis

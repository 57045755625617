import { EaseChatClient ,  EaseChatSDK } from '@/IM/initwebsdk'
import dateFormat from '@/utils/dateFormater'
import storage from 'store2'
import { messageType } from '@/constant'
import { createMessage } from '@/utils/handleSomeData'
import { requestHttpGet , requestHttpPost , requestHttpPostByOnline,ipcApiRoute } from '@/api/main'
import _ from 'lodash'
const { ALL_MESSAGE_TYPE } = messageType
import apiUrl from '@/utils/config'
const MessageUtils = {
  //根据用户ID获取头像
  async getUserinfoByto(toid){
    let userinfo = await EaseChatClient.fetchUserInfoById(toid)
    let userInfos = []
    userInfos.push(userinfo.data[toid]['avatarurl'])
    //获取用户备注并进行缓存
    let groupNoteInfo = await requestHttpGet(apiUrl+'/admin-api/supplier/conversation-remark/get-info', {
      conversationId: toid, userId: storage.get("ImAcount")
    })
    if(groupNoteInfo.code == 0){
      if(groupNoteInfo.data!=null){
        userInfos.push(groupNoteInfo.data.name)
      }else{
        userInfos.push(userinfo.data[toid]['nickname'])
      }
    }
    
    console.log(userInfos)
    return userInfos
  },
  //检测消息归属人
  async checkFromUser(item) {
    if (item.from == storage.get('ImAcount')) {
      //本人消息
      item.btype = 1
    } else {
      //他人消息
      item.btype = 0
    }
    console.log()
    if(storage.has('header-avatarurl'+item.from) || storage.has('header-name'+item.from)){
      if(storage.has('header-avatarurl'+item.from)){
        item.avatarurl = storage.get('header-avatarurl'+item.from)
      }
      if(storage.has('header-name'+item.from)){
        item.name = storage.get('header-name'+item.from)
      }
    }else{
      let uinfo = await this.getUserinfoByto(item.from)
      item.avatarurl = uinfo[0]
      item.name = uinfo[1]
      storage.set('header-avatarurl'+item.from,uinfo[0])
      storage.set('header-name'+item.from,uinfo[1])
      
    }
    
  },
  //创建本地消息体
  async CreateLoaclMessage(data) {
    console.log("接收到本地消息", data)
    await this.checkFromUser(data)
    data.fromtype = "local"
    data.order = data.time
    data.time = dateFormat('MM/DD HH:mm', data.time)
    //根据自定义消息类型获取自定义消息按钮
    await this.getCustomDetail(data)
    await this.getTypes(data)

    //更新本地缓存
  },
  //创建服务器消息体
  async CreateServiceMessage(data) {
    await this.checkFromUser(data)
    data.order = data.time
    data.time = dateFormat('MM/DD HH:mm', data.time)
    //根据自定义消息类型获取自定义消息按钮
    await this.getCustomDetail(data)
    await this.getTypes(data)

    //更新本地缓存
  },
  //根据自定义消息类型获取自定义消息按钮
  async getCustomDetail(data){
    console.log(data)
    if(data.type != 'custom'){
      return 
    }
    if(data.customExts == "selectOrder"){
        //结算单消息
        data.customExts.actionType = "亲，已帮您选择好商品规格，喜欢的话可点击“立即接单”完成下单"
        data.customExts.type = "selectOrder"
    }
    let orderId = 0
    if(data.customExts.id){
      orderId = data.customExts.id
    }
    if(data.customExts.orderId){
      orderId = data.customExts.orderId
    }
    // 设计订单消息
    if(data.customExts.type == 'designerOrder'){
      let orderInfo = await requestHttpGet(apiUrl+'/admin-api/trade/order/get-detail-pc-im', {id:orderId})
      console.log(orderInfo)
      if(orderInfo.data == null){
        data.customExts.actionType = "下单失败"
      }else{
        data.customExts.actionType = this.getOrderButtonText(orderInfo.data)
        data.customExts.actionNo = this.getOrderButtonNo(orderInfo.data)
        if(orderInfo.data.status == data.customExts.status){
          data.customExts.actionName = this.getOrderButtonName(orderInfo.data)
          data.customExts.actionStatus = true
        }else{
          data.customExts.actionName = this.getOrderButtonNameByMessage(data.customExts)
          data.customExts.actionStatus = false
        }
        data.customExts.name = this.getOrderButtonProductName(orderInfo.data)
      }
     
    }
    // 补差订单消息
    if(data.customExts.type == 'designerFixOrder'){
      let orderInfo = await requestHttpGet(apiUrl+'/admin-api/trade/order/get-fix-detail-pc-im', {id:orderId})
      data.customExts.actionType = this.getOrderButtonText(orderInfo.data)
      data.customExts.actionNo = this.getOrderButtonNo(orderInfo.data)
      if(orderInfo.data.status == data.customExts.status){
        data.customExts.actionName = this.getOrderButtonName(orderInfo.data)
        data.customExts.actionStatus = true
      }else{
        data.customExts.actionName = this.getOrderButtonNameByMessage(data.customExts)
        data.customExts.actionStatus = false
      }
      data.customExts.name = this.getOrderButtonProductName(orderInfo.data)
      // console.log(orderInfo)
    }
    // 改图订单消息
    if(data.customExts.type == 'designerChangeOrder'){
      let orderInfo = await requestHttpGet(apiUrl+'/admin-api/trade/order/get-change-detail-pc-im', {id:orderId})
      data.customExts.actionType = this.getOrderButtonText(orderInfo.data)
      data.customExts.actionNo = this.getOrderButtonNo(orderInfo.data)
      if(orderInfo.data.status == data.customExts.status){
        data.customExts.actionName = this.getOrderButtonName(orderInfo.data)
        data.customExts.actionStatus = true
      }else{
        data.customExts.actionName = this.getOrderButtonNameByMessage(data.customExts)
        data.customExts.actionStatus = false
      }
      data.customExts.name = this.getOrderButtonProductName(orderInfo.data)
      // console.log(orderInfo)
    }
    // 商品消息
    if(data.customExts.type == 'designerProduct'){
      // let orderInfo = await requestHttpGet(apiUrl+'/admin-api/product/spu/get-detail-pc-im', {id:data.customExts.id})
      data.customExts.actionType = ""
      data.customExts.actionNo = ""
      data.customExts.actionName = "查看详情"
    }
    if(data.customExts.type == 'renderProduct'){
      // let orderInfo = await requestHttpGet(apiUrl+'/admin-api/product/spu/get-detail-pc-im', {id:data.customExts.id})
      data.customExts.actionType = ""
      data.customExts.actionNo = ""
      data.customExts.actionName = "查看详情"
    }
  },
  getOrderButtonNo(e){
    return "订单编号："+e.no
  },
  getOrderButtonText(e){
    let res = ""
   
    if(e.refundStatus!=0){
      switch (e.refundStatus) {
        case 10:
          res = "部分退款完成"
          break;
        case 20:
          res = "全部退款完成"
          break;
        default:
          res = "退款中"
      }
      return res
    }
    switch (e.status) {
      case 0:
        res = "请您支付订单"
        break;
      case 10:
        res = "正在对接中"
        break;
      case 20:
        res = "请您确认需求"
        break;
      case 30:
        res = "正在制图中"
        break;
      case 40:
        res = "请您确认效果图"
        break;
      case 41:
        res = "后台审核效果图中"
        break;
      case 50:
        res = "您的订单已完成"
        break;
      case 60:
        res = "请您确认改图需求"
        break;
      case 70:
        res = "正在改图制图中"
        break;
      case 100:
        res = "您的订单已关闭"
        break;
      default:
        res = "申请退款"
    }
    return res
  },
  getOrderButtonProductName(e){
    return e.items[0].spuName
  },
  getOrderButtonNameByMessage(e){
    let res = ""
    if(parseInt(e.refundStatus)!=0){
      switch (parseInt(e.refundStatus)) {
        case 10:
          res = "部分退款完成"
          break;
        case 20:
          res = "全部退款完成"
          break;
        default:
          res = "退款中"
      }
      return res
    }
    switch (parseInt(e.status)) {
      case 0:
        res = "已支付"
        break;
      case 10:
        res = "对接中"
        break;
      case 20:
        res = "已确认需求"
        break;
      case 30:
        res = "制图中"
        break;
      case 40:
        res = "已确认效果图"
        break;
      case 41:
        res = "已审核效果图"
        break;
      case 50:
        res = "已完成"
        break;
      case 60:
        res = "已确认改图需求"
        break;
      case 70:
        res = "制图完成"
        break;
      case 100:
        res = "已关闭"
        break;
      default:
        res = "已处理"
    }
    return res
  },
  getOrderButtonName(e){
    let res = ""
    if(e.refundStatus!=0){
      switch (e.refundStatus) {
        case 10:
          res = "部分退款完成"
          break;
        case 20:
          res = "全部退款完成"
          break;
        default:
          res = "退款中"
      }
      return res
    }
    switch (e.status) {
      case 0:
        res = "用户待支付"
        break;
      case 10:
        res = "对接中"
        break;
      case 20:
        res = "用户待确认需求"
        break;
      case 30:
        res = "制图中"
        break;
      case 40:
        res = "用户待确认效果图"
        break;
      case 41:
        res = "后台待审核效果图"
        break;
      case 50:
        res = "已完成"
        break;
      case 60:
        res = "用户待确认改图需求"
        break;
      case 70:
        res = "改图制图中"
        break;
      case 100:
        res = "已关闭"
        break;
      default:
        res = "用户申请退款"
    }
    return res
  },
  async getsgtInfo(v){
    let obj = {}
    let textmsg = v.msg
    let design_ids = textmsg.split("design_ids=")[1]
   
    var url = "https://www.binliyoujia.com/index.php/index.php?ctl=Index&met=getdesignimg_details&typ=json";
    var data = {
      design_ids: design_ids,
      user_id:storage.get('us'),
    };
    let  dsinfo = await  requestHttpGet(url, data)
    console.log(dsinfo)
    
    obj.status = dsinfo.data.fengge
    obj.image = dsinfo.data.fengmian
    obj.title = dsinfo.data.fengge
    obj.design_ids = design_ids
    
    console.log(obj)
    v.newmessage = obj
  },
  async getShopInfo(v){
    let obj = {}
    let textmsg = v.msg
    let shopid = textmsg.split("id=")[1]
    var url = 'https://www.binliyoujia.com/index.php/index.php?ctl=Shop&met=getStoreInfo&typ=json';
    var data = {
      k: storage.get('ks'),
      u: storage.get('us'),
      shop_id: shopid,
    }
    let  resshop = await  requestHttpGet(url, data)
    if(resshop.status == 200){
      obj.status = resshop.data.store_info.store_name
      obj.image = resshop.data.store_info.store_avatar
      obj.title = resshop.data.store_info.store_name
      obj.shop_id = resshop.data.store_info.shop_id
      obj.user_im_username = resshop.data.store_info.user_id
    }
   
    v.newmessage = obj
  },
   async getGoodsInfo(v){
    //获取信息
    let obj = {}
    let textmsg = v.msg
    let goodsid = textmsg.split("gid=")[1]
    console.log(goodsid)
    var url = 'https://www.binliyoujia.com/index.php/index.php?ctl=Goods_Goods&met=goods&typ=json';
    var data = {
      k: storage.get('ks'),
      u: storage.get('us'),
      goods_id: goodsid,
    }
    let  resgoods = await  requestHttpGet(url, data)
    if(resgoods.status == 200){
      obj.status = resgoods.data.goods_info.goods_name
      obj.image = resgoods.data.goods_info.goods_image
      obj.title = resgoods.data.goods_info.goods_name
      obj.goods_id = resgoods.data.goods_info.goods_id
      console.log(obj)
      v.newmessage = obj
    }else{
      obj.status = "商品已下架"
      obj.status = ""
      obj.image = ""
      obj.title = ""
      obj.goods_id = ""
      v.newmessage = obj
    }
    
  },
  async getTypes(v){
    if(v.type == "txt"){
      let textmsg = v.msg
      if(textmsg.indexOf("http") == 0){
        if(textmsg.indexOf("Goods_Goods")>=0 || textmsg.indexOf("Supplier_Goods")>=0){
          //设计商品
          //普通商品
          await this.getGoodsInfo(v)
          return "goods"
        }else if(textmsg.indexOf("Shop")>=0){
          //店铺
          await this.getShopInfo(v)
          return "goods"
        }else if(textmsg.indexOf("renderingHall")>=0){
          //效果图
          await this.getsgtInfo(v)
          return "goods"
        }else{
          //网址
          return "http"
        }
      }
    }
    return "false"
  },
  //获取历史消息
  async getHistoryMessages(userid, type,page) {
    let MessageList = []
    let options = {
      targetId: userid,
      pageSize: 10,
      cursor: page,
      chatType: type,
      searchDirection: 'up'
    }
    //获取消息
    let res = await EaseChatClient.getHistoryMessages(options)
    //排序
    res.messages.sort((a, b) => { return a.time - b.time })
    for (let index = 0; index < res.messages.length; index++) {
      let element = res.messages[index];
      element.deliver = true
      await this.CreateServiceMessage(element)
      MessageList.push(element)
    }
    //返回消息数组
    // console.log(MessageList)
    return MessageList
  },
  //发送消息 ---------------------------------------------------------------------------------------
  //文本消息
  async sendTextMessage(userid,chatType,textContent,MessageList,vm){
    if (textContent.match(/^\s*$/)) return

      //获取当前用户出图时间
      let designer = await requestHttpGet(apiUrl+'/admin-api/supplier/user/get-designer-pc-im')
      if(designer.data != null){
        if(textContent.includes("{outpic}")){
          console.log(designer.data.designer.acceptanceDate.join("-"))
          const regex = new RegExp("{outpic}", 'g');
          textContent = textContent.replace(regex, designer.data.designer.acceptanceDate.join("-"));
        }
      }
      
      const msgOptions = {
        id: userid,
        chatType: chatType,
        msg: textContent,
        ext:{url:12312312},
      }
      textContent = ''
      try {
        let options = {
          chatType: msgOptions.chatType, // 会话类型
          type: ALL_MESSAGE_TYPE.TEXT, // 消息类型。
          to: msgOptions.id, // 消息接收方（用户 ID)。
          msg: msgOptions.msg, // 消息内容。
          ext: msgOptions.ext, //扩展
        }
        let msg = await EaseChatSDK.message.create(options)
        await EaseChatClient.send(msg)
     
        await this.sendPushMsg(msg.to,msg)
        msg.from = storage.get('ImAcount')
        await this.CreateLoaclMessage(msg)
        vm.$emit('sendM', msg)
        MessageList.push(msg)
      } catch (error) {
        console.log('>>>>>>>发送失败+++++++', error)
      }
  },
  //图片消息
  sendImagesMessage(userid,chatType,imgFile,MessageList,callBack){
    const file = {
      data: null,// file 对象。
      filename: imgFile.name,//文件名称。
      filetype: ''//文件类型。
    }
    const url = window.URL || window.webkitURL
    const img = new Image()              //手动创建一个Image对象
    const msgOptions = {
      id: userid,
      chatType: chatType,
      file: file,
      width: 0,
      height: 0,
      ext:{
        filename:imgFile.name
      }
    }
    //读取图片的宽高
    file.data = imgFile
    file.filename = imgFile.name
    file.filetype = imgFile.type
    img.src = url.createObjectURL(imgFile)//创建Image的对象的url
    img.onload = async () => {
      msgOptions.width = img.width
      msgOptions.height = img.height
      try {
        const options = createMessage.createOptions({ msgType: ALL_MESSAGE_TYPE.IMAGE, msgOptions: _.cloneDeep(msgOptions) })
        const msg = EaseChatSDK.message.create(options)
        EaseChatClient.send(msg)
        await this.sendPushMsg(msg.to,msg)
        msg.from = storage.get('ImAcount')
        await this.CreateLoaclMessage(msg)
        callBack(msg)
        MessageList.push(msg)

      } catch (error) {
        console.log('>>>>>发送失败', error)
      }
    }
  },
   //视频消息
   async sendVideoMessages(userid,chatType,File,MessageList,vm){
    const commonFile = File
      const file = {
        data: commonFile,           // file 对象。
        filename: commonFile.name, //文件名称。
        filetype: commonFile.type, //文件类型。
        size: commonFile.size
      }
      // console.log('>>>>>调用发送文件', file)
      const msgOptions = {
        id: userid,
        chatType: chatType,
        file: file,
      }
      try {
        const options = createMessage.createOptions({ msgType: ALL_MESSAGE_TYPE.VIDEO, msgOptions: _.cloneDeep(msgOptions) })
        const msg = EaseChatSDK.message.create(options)
        EaseChatClient.send(msg)
        await this.sendPushMsg(msg.to,msg)
        msg.from = storage.get('ImAcount')
        msg.file_length = msg.file.size
        await this.CreateLoaclMessage(msg)
        vm.$emit('sendM', msg)
        MessageList.push(msg)
      } catch (error) {
        console.log('>>>>file error', error)
      }
  },
  //文件消息
  async sendFilesMessages(userid,chatType,File,MessageList,vm){
    const commonFile = File
      const file = {
        data: commonFile,           // file 对象。
        filename: commonFile.name, //文件名称。
        filetype: commonFile.type, //文件类型。
        size: commonFile.size
      }
      console.log(commonFile)
      // console.log('>>>>>调用发送文件', file)
      const msgOptions = {
        id: userid,
        chatType: chatType,
        file: file,
      }
      try {
        const options = createMessage.createOptions({ msgType: ALL_MESSAGE_TYPE.FILE, msgOptions: _.cloneDeep(msgOptions) })
        const msg = EaseChatSDK.message.create(options)
        EaseChatClient.send(msg)
        await this.sendPushMsg(msg.to,msg)
        msg.from = storage.get('ImAcount')
        msg.file_length = msg.file.size
        await this.CreateLoaclMessage(msg)
        vm.$emit('sendM', msg)
        MessageList.push(msg)
      } catch (error) {
        console.log('>>>>file error', error)
      }
  },
  //语音消息
  async sendAudioMessages(userid,chatType,audioData,MessageList,vm){
    // console.log("发送录音")
      const file = {
        url: EaseChatSDK.utils.parseDownloadResponse(audioData.src),
        filename: '录音.mp3',
        filetype: '.mp3',
        data: audioData.src
      }
      console.log(file)
      // console.log('>>>>>audioData', audioData, file)
      const msgOptions = {
        id: userid,
        chatType: chatType,
        file: file,
        length: audioData.length
      }
      try {
        const options = createMessage.createOptions({ msgType: ALL_MESSAGE_TYPE.AUDIO, msgOptions: _.cloneDeep(msgOptions) })
        const msg = EaseChatSDK.message.create(options)
        EaseChatClient.send(msg)
        await this.sendPushMsg(msg.to,msg)
        msg.file_length = msg.file.size
        msg.from = storage.get('ImAcount')
        await this.CreateLoaclMessage(msg)
        vm.$emit('sendM', msg)
        MessageList.push(msg)
      } catch (error) {
        console.log(error)
      }
  },
  //发送自定义消息
  async sendCustomMessages(userid,chatType,objCustom,MessageList,vm){
    // 通过键值对设置自定义消息内容。
    console.log(objCustom)
    let customExts = objCustom;
    const msgOptions = {
      id: userid,
      chatType: chatType,
      customEvent: customExts.type, // 自定义事件。
      customExts: customExts, // 消息内容，key/value 需要 string 类型。
      ext:customExts, // 消息扩展。
    }
    try {
      const options = createMessage.createOptions({ msgType: ALL_MESSAGE_TYPE.CUSTOM, msgOptions: _.cloneDeep(msgOptions) })
      const msg = EaseChatSDK.message.create(options)
      EaseChatClient.send(msg)
      await this.sendPushMsg(msg.to,msg)
      msg.from = storage.get('ImAcount')
      await this.CreateLoaclMessage(msg)
      vm.$emit('sendM', msg)
      MessageList.push(msg)
    } catch (error) {
      console.log(error)
    }
  },
  //离线消息
  async sendPushMsg(uid,message){
    console.log(message)
    if(message.chatType == "groupChat"){
      //群组离线

      //获取群组成员
      let data = await EaseChatClient.getGroupInfo({groupId:message.to})
      let groupInfo = data.data[0]
      let arruser = []
      let uarr = []
      for (let j = 0; j < groupInfo.affiliations.length; j++) {
        let jitem = groupInfo.affiliations[j];
        if(jitem.member){
          arruser.push(jitem.member)
        }else if(jitem.owner){
          arruser.push(jitem.owner)
        }
      }
      //过滤调自己
			for (var i = 0; i < arruser.length; i++) {
				let item = arruser[i]
				if(item != storage.get('ImAcount')){
            if(item.indexOf('im')>=0){
              uarr.push(item.split("im")[1]);
            }
            if(item.indexOf('bk')>=0){
              uarr.push(item.split("bk")[1]);
            }
				}
			}
			console.log(uarr)
			console.log(uid,"调用离线通知~")
      let nickname = storage.get('UserName')
      let avatarurl = storage.get('UserHeader')
      let contentz = "您有新的聊天信息啦,请注意查收"
      if (message.type == "txt") {
        if(message.msg.length > 40){
          contentz = message.msg.substr(0,35)+"..."
        }else{
          contentz = message.msg
        }
      }
      if (message.type == "txt") {
        if(message.msg.indexOf('[') >= 0){
          contentz = "[表情]"
        }
      }
      if (message.type == "img") {
        contentz = "[图片]"
      }
      if (message.type == "file") {
        contentz = "[文件]"
      }
      if (message.type == "audio") {
        contentz = "[语音]"
      }
      if (message.type == "video") {
        contentz = "[视频]"
      }
      if (message.type == "custom") {
        contentz = "[链接]"
      }
      let obja = {
        getui_alias: uarr, //接收人
        title:groupInfo.name+"群组向您发送了一条新消息。",
        content:contentz,
        payload:{"type":4,"orderId":message.to,chatType:message.chatType,nickname:nickname,avatarurl:avatarurl},
        request_id:new Date().getTime()+"s"
      }
      console.log(obja)
      let ras = await requestHttpPostByOnline('https://fc-mp-d9355a6b-424f-47cc-b10b-4865ae8b23a6.next.bspapp.com/sysPushForUserId', obja)
      console.log(ras)
    }else{
      //获取用户是否在线
        console.log(uid,"调用离线通知~") 
        console.log(uid,message)
        //发送人头像
        //发送人user_name
        let nickname = storage.get('UserName')
        let avatarurl = storage.get('UserHeader')
        let contentz = "您有新的聊天信息啦,请注意查收"
        if (message.type == "txt") {
          if(message.msg.length > 40){
            contentz = message.msg.substr(0,35)+"..."
          }else{
            contentz = message.msg
          }
        }
        if (message.type == "txt") {
          if(message.msg.indexOf('[') >= 0){
            contentz = "[表情]"
          }
        }
        if (message.type == "img") {
          contentz = "[图片]"
        }
        if (message.type == "file") {
          contentz = "[文件]"
        }
        if (message.type == "audio") {
          contentz = "[语音]"
        }
        if (message.type == "video") {
          contentz = "[视频]"
        }
        if (message.type == "custom") {
          contentz = "[链接]"
        }
        if(uid){
          let muid = ""
          if(uid.indexOf("im")>=0){
            muid = uid.split("im")[1]
          }else{
            muid = uid.split("bk")[1]
          }
          let obja = {
            getui_alias: muid, //发送人
            title:nickname+"向您发送了一条新消息。",
            content:contentz,
            payload:{"type":1,"orderId":muid,chatType:message.chatType,nickname:nickname,avatarurl:avatarurl},
            request_id:new Date().getTime()+"s"
          }
          console.log(obja)
          if(muid!=""){
            let ras = await requestHttpPostByOnline('https://fc-mp-d9355a6b-424f-47cc-b10b-4865ae8b23a6.next.bspapp.com/sysPushForUserId', obja)
            console.log(ras)
          }
        }
      // }
    }
    
  },

  //发送会话已读回执
  async sendMessageGoBack(userid,chatType,vuedom){
    let option = {
      chatType:chatType, // 会话类型。
      type: "channel", // 消息类型。
      to: userid, // 接收消息对象的用户 ID。
    };
    console.log("发送~~~~~已读会话",option)
    const msg = EaseChatSDK.message.create(option)
    EaseChatClient.send(msg)
    if(chatType == 'groupChat'){
      //群组
      let res  = await vuedom.$messageDb.getItem("grouplist")
      res.forEach(rl => {
        if(rl.id == userid){
          rl.unread_num = 0
        }
      });
      await vuedom.$messageDb.setItem('grouplist',res)
    }else if(chatType == 'singleChat'){
      //单聊
      let res  = await vuedom.$messageDb.getItem("corlist")
      res.forEach(rl => {
        if(rl.id == userid){
          rl.unread_num = 0
        }
      });
      await vuedom.$messageDb.setItem('corlist',res)
    }
  },
  //发送消息已读回执
  async sendMessageNoteGoBack(userid,chatType,id,vuedom){
    let option = {
      type: "read", // 消息是否已读。
      chatType:chatType, // 会话类型。
      to: userid, // 接收消息对象的用户 ID。
      id: id, // 需要发送已读回执的消息 ID。
    };
    if(chatType == 'groupChat'){
      //群组
      let res  = await vuedom.$messageDb.getItem("grouplist")
      res.forEach(rl => {
        if(rl.id == userid){
          rl.unread_num = 0
        }
      });
      await vuedom.$messageDb.setItem('grouplist',res)
    }else if(chatType == 'singleChat'){
      //单聊
      let res  = await vuedom.$messageDb.getItem("corlist")
      res.forEach(rl => {
        if(rl.id == userid){
          rl.unread_num = 0
        }
      });
      await vuedom.$messageDb.setItem('corlist',res)
    }
    // console.log("发送~~~~~已读消息",option)
    const msg = EaseChatSDK.message.create(option)
    EaseChatClient.send(msg)
  },
  //自动保存文件
  async autoSaveFile(msg,ipc){
    // console.log("自动存储消息")
    // console.log(msg)
    for (let index = 0; index < msg.length; index++) {
      const e = msg[index];
      if(e.type == "img"){
        console.log("自动存储消息img",e)
        await this.saveFileBase(e,ipc)
        if(ipc){
          e.localurl = "file:///C:/Users/Administrator/AppData/Local/Temp/binli/"+e.ext.filename
        }
      }
      if(e.type == "file"){
        console.log("自动存储消息file",e)
        await this.saveFileBase(e,ipc)
        if(ipc){
          e.localurl = "file:///C:/Users/Administrator/AppData/Local/Temp/binli/"+e.filename
        }
      }
    }
  },
  //自动保存文件
  async autoNewSaveFile(msg,ipc){
    // console.log("自动存储消息")
    // console.log(msg)
    if(msg.type == "img"){
      console.log("自动存储消息img",msg)
      await this.saveFileBase(msg,ipc)
      if(ipc){
        msg.localurl = "file:///C:/Users/Administrator/AppData/Local/Temp/binli/"+msg.ext.filename
      }
    }
    if(msg.type == "file"){
      console.log("自动存储消息file",msg)
      await this.saveFileBase(msg,ipc)
      if(ipc){
        msg.localurl = "file:///C:/Users/Administrator/AppData/Local/Temp/binli/"+msg.filename
      }
    }
  },
  //保存文件
  async saveFileBase(msg,ipc){
    console.log(ipc)
    if (ipc) {
      console.log("自动存储消息")
      let res = await ipc.invoke(ipcApiRoute.saveFile, {name:msg.filename,url:msg.url,id:msg.id,type:msg.type})
      console.log(res)
      return res
    }
  }
}

export {
  MessageUtils
}

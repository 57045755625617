/**
 * 基础路由
 * @type { *[] }
 */
import {AppSider, Menu , MainSider } from '@/layouts'

const RouteView = {
  name: 'RouteView',
  render: h => h('router-view')
}
export const constantRouterMap = [
  {
    path: '/',
    component: MainSider,
    meta: {
      rand: Math.random()
    },
    children: [
      {
        path: '/main',
        name: 'Main',
        component: Menu,
        meta: {
          rand: Math.random()
        },
        props: { id: 'main' , ir:false},
        redirect: { name: 'MainIndex' },
        children: [
          {
            path: '/main/index',
            name: 'MainIndex',
            meta: {
              rand: Math.random()
            },
            component: () => import('@/views/main/Index')
          }
        ]
      },
      {
        path: '/crowd',
        name: 'Crowd',
        component: Menu,
        meta: {
          rand: Math.random()
        },
        props: { id: 'crowd' , ir:false},
        redirect: { name: 'CrowdIndex' },
        children: [
          {
            path: '/crowd/index',
            name: 'CrowdIndex',
            meta: {
              rand: Math.random()
            },
            component: () => import('@/views/crowd/Index')
          }
        ]
      },
      {
        path: '/friend',
        name: 'Friend',
        component: Menu,
        props: { id: 'friend' ,ir:false},
        redirect: { name: 'FriendIndex' },
        meta: {
          rand: Math.random()
        },
        children: [
          {
            path: '/friend/index',
            name: 'FriendIndex',
            meta: {
              rand: Math.random()
            },
            component: () => import('@/views/friend/Index')
          }
        ]
      }
    ]
  },
  {
    path: '/login',
    component: () => import('@/views/login/Index'),
    name: 'Login', 
    meta: {
      title: '登陆环信',
      rand: Math.random()
    },
  },
  { 
    path: '/userxy',
    component: () => import('@/views/userxy/Index'),
    name: 'UserXy',
    meta: {
      title: '用户协议',
      rand: Math.random()
    },
  },
  { 
    path: '/yszc',
    component: () => import('@/views/yszc/Index'),
    name: 'YsZc',
    meta: {
      title: '隐私政策',
      rand: Math.random()
    },
  },
  { 
    path: '/preimg',
    component: () => import('@/views/preimg/Index'),
    name: 'PreImg',
    meta: {
      title: '图片预览',
    },
  },
  {
    path: '/findpwd',
    component: () => import('@/views/findpwd/Index'),
    name: 'FindPwd',
    meta: { 
      title: '找回密码',
    },
  }
]


// export const constantRouterMap1 = [
//   {
//     path: '/',
//     component: AppSider,
//     children: [
//       {
//         path: '/base',
//         name: 'Base',
//         component: Menu,
//         props: { id: 'base' },
//         redirect: { name: 'BaseFileIndex' },
//         children: [
//           {
//             path: '/base/file/index',
//             name: 'BaseFileIndex',
//             component: () => import('@/views/base/file/Index')
//           },
//           {
//             path: '/base/socket/ipc',
//             name: 'BaseSocketIpc',
//             component: () => import('@/views/base/socket/Ipc')
//           },
//           {
//             path: '/base/db/index',
//             name: 'BaseDBIndex',
//             component: () => import('@/views/base/db/Index')
//           },
//           {
//             path: '/base/sqlitedb/index',
//             name: 'BaseSqliteDBIndex',
//             component: () => import('@/views/base/sqlitedb/Index')
//           },
//           {
//             path: '/base/windowview/index',
//             name: 'BaseWindowViewIndex',
//             component: () => import('@/views/base/windowview/Index')
//           },
//           {
//             path: '/base/window/index',
//             name: 'BaseWindowIndex',
//             component: () => import('@/views/base/window/Index')
//           },
//           {
//             path: '/base/jobs/index',
//             name: 'BaseJobsIndex',
//             component: () => import('@/views/base/jobs/Index')
//           },
//           {
//             path: '/base/notification/index',
//             name: 'BaseNotificationIndex',
//             component: () => import('@/views/base/notification/Index')
//           },
//           {
//             path: '/base/powermonitor/index',
//             name: 'BasePowerMonitorIndex',
//             component: () => import('@/views/base/powermonitor/Index')
//           },
//           {
//             path: '/base/screen/index',
//             name: 'BaseScreenIndex',
//             component: () => import('@/views/base/screen/Index')
//           },
//           {
//             path: '/base/theme/index',
//             name: 'BaseThemeIndex',
//             component: () => import('@/views/base/theme/Index')
//           },                               
//           {
//             path: '/base/software/index',
//             name: 'BaseSoftwareIndex',
//             component: () => import('@/views/base/software/Index')
//           },
//           {
//             path: '/base/socket/httpserver',
//             name: 'BaseSocketHttpServer',
//             component: () => import('@/views/base/socket/HttpServer')
//           },
//           {
//             path: '/base/socket/socketserver',
//             name: 'BaseSocketSocketServer',
//             component: () => import('@/views/base/socket/SocketServer')
//           },          
//           {
//             path: '/base/system/index',
//             name: 'BaseSystemIndex',
//             component: () => import('@/views/base/system/Index')
//           },
//           {
//             path: '/base/testapi/index',
//             name: 'BaseTestApiIndex',
//             component: () => import('@/views/base/testapi/Index')
//           },
//           {
//             path: '/base/updater/index',
//             name: 'BaseUpdaterIndex',
//             component: () => import('@/views/base/updater/Index')
//           },  
//         ]  
//       },
//       {
//         path: '/other',
//         name: 'Other',
//         component: Menu,
//         props: { id: 'other' },
//         redirect: { name: 'OtherTestIndex' },
//         children: [
//           {
//             path: '/other/test/index',
//             name: 'OtherTestIndex',
//             component: () => import('@/views/other/test/Index')
//           },
//           {
//             path: '/other/java/index',
//             name: 'OtherJavaIndex',
//             component: () => import('@/views/other/java/Index')
//           }
//         ] 
//       }
//     ]
//   },
//   {
//     path: '/login',
//     component: () => import('@/views/login/Index'),
//     name: 'Login',
//     meta: {
//       title: '登陆环信',
//     },
//   },
//   {
//     path: '/findpwd',
//     component: () => import('@/views/findpwd/Index'),
//     name: 'FindPwd',
//     meta: { 
//       title: '找回密码',
//     },
//   },
//   /* 登陆页 */
//   {
//     path: '/special',
//     component: RouteView,
//     //redirect: '/special/subwindow',
//     children: [
//       {
//         path: 'subwindow',
//         name: 'SpecialSubwindowIpc',
//         component: () => import('@/views/base/subwindow/Ipc')
//       }
//     ]
//   }
// ]
